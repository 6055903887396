// Base values for meta tags
// So they can be added as suffixes on different pages
// Via <svelte:head>

export const siteBaseUrl = 'https://siposdani87.com';

export const appName = 'BLOG.siposdani87.com';
export const appKeywords = ['Sipos Dániel', 'Senior Software Engineer', 'Developer Blog'];
export const appDescription =
	'Blog web application, that displays wonderful markdown articles via (MD/MDX/MDsveX). There are a lot of developer issues, which I met in the past and for sure I will meet in the present.';
export const appAuthor = 'Dániel Sipos';
